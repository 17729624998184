(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function () {
    var globalTimeout = null;

    var doSearch = function doSearch(inputField) {
        var searchString = inputField.val();
        if (searchString.length < 2) {
            // console.log('too short search string');
            return;
        }

        var searchUrl = jsconf.baseUrl + '/search/json';
        var searchData = {
            search: searchString
        };

        $.post(searchUrl, searchData, function (response) {
            $('#search-form-dropdown').remove();
            $('<div/>', {
                id: 'search-form-dropdown'
            }).appendTo('body');

            $('#search-form-dropdown').css({
                position: 'absolute',
                top: inputField.offset().top + inputField.outerHeight() + 'px',
                left: inputField.offset().left + 'px',
                width: inputField.outerWidth() + 'px'
            });

            for (var i = 0; i < response.length; i++) {
                var newDiv = $('<div/>', {
                    class: 'search-form-dropdown-item'
                });

                var htmlContent = '';
                if (response[i].track.length > 0) {
                    htmlContent = response[i].artist + ' - ' + response[i].track;
                } else {
                    htmlContent = response[i].artist;
                }
                htmlContent = htmlContent.replace(/\\/g, '');

                newDiv.html(htmlContent);
                newDiv.attr('data-url', response[i].url);
                newDiv.appendTo('#search-form-dropdown');

                // console.log(response[i]);
            }
        });
    };

    $('.search-form-input').keydown(function (event) {
        // console.log(event.which);

        if (event.which === 38) {
            // arrow up
            event.preventDefault();
        } else if (event.which === 40) {
            // arrow down
            event.preventDefault();
        }
    });

    $('.search-form-input').keyup(function (event) {
        var _this = this;

        if (event.which === 13) {
            // let searchString = $(this).val();
            // console.log('search string: ' + searchString);

            var _selectedItem = 0;

            $('.search-form-dropdown-item').each(function (index) {
                if ($(this).hasClass('item-selected')) {
                    // starts from 0, so add 1
                    _selectedItem = index + 1;
                }
            });

            // if some dropdown item is highlighted, go to exact page
            if (_selectedItem > 0) {
                var url = $('.search-form-dropdown-item:nth-of-type(' + _selectedItem + ')').attr('data-url');

                window.location.href = url;
            } else {
                // dropdown item not highlighted, go to google results
                var searchString = $(this).val();

                if (searchString.length) {
                    var queryString = searchString + ' site:flashlyrics.com';
                    var url = 'https://www.google.com/search?q=' + encodeURIComponent(queryString);

                    window.location.href = url;
                }
            }
        } else if (event.which === 27) {
            $('#search-form-dropdown').remove();
        } else if (event.which === 38 || event.which === 40) {
            var numItems = $('.search-form-dropdown-item').length;
            var selectedItem = 0;

            $('.search-form-dropdown-item').each(function (index) {
                if ($(this).hasClass('item-selected')) {
                    selectedItem = index + 1;
                }
            });

            if (event.which === 38) {
                selectedItem--;

                if (selectedItem < 0) {
                    selectedItem = numItems;
                }
            } else if (event.which === 40) {
                selectedItem++;

                if (selectedItem > numItems) {
                    selectedItem = 0;
                }
            }

            $('.search-form-dropdown-item').removeClass('item-selected');
            $('.search-form-dropdown-item:nth-of-type(' + selectedItem + ')').addClass('item-selected');
        } else if (event.which === 37) {
            // console.log('left');
        } else if (event.which === 39) {
            // console.log('right');
        } else {
            (function () {
                // console.log('keyup: ' + event.which);

                if (globalTimeout !== null) {
                    clearTimeout(globalTimeout);
                }

                var inputField = $(_this);
                globalTimeout = setTimeout(function () {
                    doSearch(inputField);
                }, 300);
            })();
        }

        //     else if (event.which == 27) {   // escape
        //         var container = $('#search-form-dropdown');

        //         container.remove();
        //     }


        //     else if (event.which == 37) {   // arrow left
        //     }

        //     else if (event.which == 39) {   // arrow right
        //     }

        //     else {
        //         if(globalTimeout != null) {
        //             clearTimeout(globalTimeout);
        //         }

        //         globalTimeout = setTimeout(doSearch, 200);
        //     }
    });

    $('body').delegate('.search-form-dropdown-item', 'mouseenter', function () {
        $('.search-form-dropdown-item').removeClass('item-selected');
        $(this).addClass('item-selected');
    });

    $('body').delegate('.search-form-dropdown-item', 'mouseleave', function () {
        $('.search-form-dropdown-item').removeClass('item-selected');
    });

    $('body').delegate('.search-form-dropdown-item', 'click', function () {
        var url = $(this).attr('data-url');

        window.location.href = url;
    });
});

},{}]},{},[1]);
